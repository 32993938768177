@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-weight: bold;
}

.App {
  width: 720px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  font-family: "Aeonik", sans-serif;
  font-weight: 500;
}

pre {
  font-family: "Aeonik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 24px;
}

pre > p {
  margin: 0;
}

@media only screen and (max-width: 720px) {
  .App {
    width: 100%;
  }
}

/* Header */

header {
  z-index: 1;
  width: 60%;
  display: flex;
  justify-content: center;
  padding: 24px;
  position: absolute;
  top: 0;
  left: 20%;
}

@media only screen and (max-width: 720px) {
  header {
    width: 100%;
    left: unset;
  }
}

header > div {
  width: 50%;
  font-size: 14px;
}

header > div > a {
  text-decoration: none;
  color: #7e7e7e;
  padding: 10px;
}

header > div > a:hover {
  cursor: pointer;
}

header > div:first-child {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
}

header > div:last-child {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
}

/* Main */

main {
  width: 100%;
  height: 100vh;
}

main > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  position: relative;
}

main > div > img {
  margin-top: 24px;
  width: 80px;
}

main > div > h1 {
  font-size: 65px;
  margin: 0;
  margin-top: 20px;
}

main > div > h2 {
  font-size: 18px;
}

@media only screen and (max-width: 720px) {
  main > div > h1 {
    font-size: 36px;
  }
  main > div > h2 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  main > div > h2 {
    font-size: 14px;
  }
}

main > div > div {
  width: 100%;
  text-align: left;
  font-size: 16px;
  overflow-y: auto;
  height: calc(100vh - 500px);
  padding-right: 15px;
}

.scrollable {
  z-index: 1; /*Fix visibility in Mozilla browser*/
}

@media only screen and (max-width: 750px) {
  main > div > div {
    padding-left: 24px;
  }
  .scrollable {
    width: 90%;
  }
}

main > div > div::-webkit-scrollbar {
  width: 2px;
  background-color: #ebebeb;
  border-radius: 2px;
}
main > div > div::-webkit-scrollbar-thumb {
  background-color: #0657f9;
  border-radius: 2px;
}
main > div > div::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 2px;
}
main > div > div {
  scrollbar-width: thin;
  scrollbar-color: #0657f9 #ebebeb;
  border-radius: 2px;
}

main > div > div > .questions-answers {
  margin-bottom: 40px;
}
main > div > .white-fade {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 63%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 5px);
  height: 100px;
}

@media only screen and (max-width: 750px) {
  main > div > .white-fade {
    width: calc(100% - 35px);
  }
}

.loading-dots {
  animation: dots 0.5s linear infinite;
  color: #0657f9;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(6, 87, 249, 0);
    text-shadow:
      0.25em 0 0 rgba(6, 87, 249, 0),
      0.5em 0 0 rgba(6, 87, 249, 0);
  }
  40% {
    color: #0657f9;
    text-shadow:
      0.25em 0 0 rgba(6, 87, 249, 0),
      0.5em 0 0 rgba(6, 87, 249, 0);
  }
  60% {
    color: rgba(6, 87, 249, 0);
    text-shadow:
      0.25em 0 0 #0657f9,
      0.5em 0 0 rgba(6, 87, 249, 0);
  }
  80%,
  100% {
    color: rgba(6, 87, 249, 0);
    text-shadow:
      0.25em 0 0 rgba(6, 87, 249, 0),
      0.5em 0 0 #0657f9;
  }
}

/* Footer */

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  justify-content: center;
  margin-bottom: 1rem;
  top: 30px;
}

footer > div > button {
  border: none;
  color: #5b5b5b;
  background-color: #f4f4f4;
  padding: 10px;
  margin: 0;
  font-size: 14px;
  z-index: 1;
}

footer > div > button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .buttons {
    top: -32px;
  }
}

footer > span {
  margin-top: 32px;
  font-size: 12px;
  color: #5b5b5b;
}

footer > div {
  display: flex;
  width: 100%;
  max-width: 720px;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

footer > div > input {
  padding: 20px;
  width: 100%;
  max-width: 720px;
  border: none;
  background: #f4f4f4;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  position: relative;
  font-size: 16px;
}

footer > div > input::placeholder {
  color: #9d9d9d;
}

footer > div > input[type="text"],
select {
  outline: none;
}

footer > div > input[type="text"] {
  outline: none;
  padding-right: 55px;
}

footer > div .send {
  position: absolute;
  top: 50%;
  left: unset;
  right: 0;
  width: 60px;
  padding: 18px;
  transform: translateY(-50%);
}

footer > div .send:hover {
  cursor: pointer;
}
